import React, { ReactNode } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

interface AppLayoutProps {
  children: ReactNode;
}

export default function AppLayout({ children }: AppLayoutProps) {
  const location = useLocation();
  // In production, when inspecting the source code, the canonical link cannot be seen, but Google does recognize it when analyzing it.
  const canonicalUrl = `https://apurata.com/app${location.pathname}`;
  return (
    <HelmetProvider>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      {children}
    </HelmetProvider>
  );
}
