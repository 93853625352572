import FetchServicesCustomErrors from './BaseFetch';

export function storeSingleField(name, value) {
  /* In case of error we dont want to stop the whole page */
  fetch(`${import.meta.env.REACT_APP_API_SERVER}/api/funnel/step1/store-single-field`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      field_name: name,
      field_value: value,
    }),
  });
}

export function getStep1PrefillData() {
  return FetchServicesCustomErrors({
    endpoint: `${
      import.meta.env.REACT_APP_API_SERVER
    }/api/funnel/step1/prefill`,
  });
}

export function fetchLoanInfo() {
  const endpoint = `${import.meta.env.REACT_APP_API_SERVER}/api/v1/loan-info`;
  return FetchServicesCustomErrors({ endpoint });
}

export function fetchGetUnpaidPrevLoanInfo() {
  const endpoint = `${import.meta.env.REACT_APP_API_SERVER}/api/v1/full-pmt-flow/get-unpaid-prev-loan-info`;
  return FetchServicesCustomErrors({ endpoint });
}

export function fetchperiodCheckPmtStatus() {
  const endpoint = `${import.meta.env.REACT_APP_API_SERVER}/api/v1/full-pmt-flow/period-check-payment-status`;
  return FetchServicesCustomErrors({ endpoint, method: 'POST' });
}

export function fetchOrderTimer(orderId, clientId) {
  const endpoint = `${
    import.meta.env.REACT_APP_API_SERVER
  }/pos/order-timer?order_id=${orderId}&client_id=${clientId}`;
  return FetchServicesCustomErrors({ endpoint });
}

export function fetchValidUrl() {
  const endpoint = `${
    import.meta.env.REACT_APP_API_SERVER
  }/api/v1/loan-info/get-valid-urls`;
  return FetchServicesCustomErrors({ endpoint });
}

export function fetchPreviousPosLoan(clientId, orderId) {
  const endpoint = `
    ${import.meta.env.REACT_APP_API_SERVER}/api/v1/pos/previous-loan/info?order_id=${orderId}&client_id=${clientId}
  `;
  return FetchServicesCustomErrors({ endpoint });
}

export function fetchRestorePreviousPosLoan(data) {
  const endpoint = `${
    import.meta.env.REACT_APP_API_SERVER
  }/api/v1/pos/previous-loan/restore`;
  return FetchServicesCustomErrors({ endpoint, method: 'POST', body: data });
}

export function prepareUrl(url) {
  if (url.startsWith('/app/')) {
    return url.replace('/app/', '/');
  }
  window.location.href = import.meta.env.REACT_APP_API_SERVER + url;
}

export function getPendingDownpayment() {
  const endpoint = `${
    import.meta.env.REACT_APP_API_SERVER
  }/pos/get-pending-downpayment`;
  return FetchServicesCustomErrors({ endpoint });
}

export function getSessionTokenNiubiz(lendId) {
  const endpoint = `${
    import.meta.env.REACT_APP_API_SERVER
  }/niubiz-api/${lendId}`;
  return FetchServicesCustomErrors({ endpoint });
}

export function acceptApproval() {
  const endpoint = `${
    import.meta.env.REACT_APP_API_SERVER
  }/pos/accept-approval`;
  return FetchServicesCustomErrors({ endpoint, method: 'POST' });
}

export function cancelApplication() {
  const endpoint = `${
    import.meta.env.REACT_APP_API_SERVER
  }/pos/cancel-application`;
  return FetchServicesCustomErrors({ endpoint, method: 'POST' });
}

export function fetchEvalStatus() {
  const endpoint = `${import.meta.env.REACT_APP_API_SERVER}/pos/eval-status`;
  return FetchServicesCustomErrors({ endpoint });
}

export function fetchValidationLoanInfo() {
  const endpoint = `${
    import.meta.env.REACT_APP_API_SERVER
  }/pos/loan/validation`;
  return FetchServicesCustomErrors({ endpoint });
}

export function sendToken(channel) {
  const endpoint = `${
    import.meta.env.REACT_APP_API_SERVER
  }/pos/validate/${channel}/resend-pin`;

  if (channel === 'phone') {
    return FetchServicesCustomErrors({
      endpoint,
      method: 'POST',
      body: {},
    });
  }

  // set to email
  return FetchServicesCustomErrors({
    endpoint,
    method: 'GET',
  });
}

export function validateToken(channel, token) {
  const endpoint = `${
    import.meta.env.REACT_APP_API_SERVER
  }/pos/validate/${channel}`;
  return FetchServicesCustomErrors({
    endpoint,
    method: 'POST',
    body: { token },
  });
}

export function editChannel(channel, value) {
  const endpoint = `${import.meta.env.REACT_APP_API_SERVER}/pos/edit_channel`;
  return FetchServicesCustomErrors({
    endpoint,
    method: 'POST',
    body: { value, channel },
  });
}

export function changeDownpayment() {
  const endpoint = `${
    import.meta.env.REACT_APP_API_SERVER
  }/pos/change_downpayment`;
  return FetchServicesCustomErrors({ endpoint });
}

export function registerPayment(data) {
  const endpoint = `${
    import.meta.env.REACT_APP_API_SERVER
  }/pos/register-payment`;
  return FetchServicesCustomErrors({ endpoint, method: 'POST', body: data });
}

export function correctPayment() {
  const endpoint = `${
    import.meta.env.REACT_APP_API_SERVER
  }/pos/correct-payment`;
  return FetchServicesCustomErrors({ endpoint, method: 'POST' });
}

export function changeValidationForIOS() {
  const endpoint = `${
    import.meta.env.REACT_APP_API_SERVER
  }/api/v1/lends/change-validation-for-ios`;
  return FetchServicesCustomErrors({ endpoint, method: 'POST' });
}

export function changeToApp() {
  const endpoint = `${
    import.meta.env.REACT_APP_API_SERVER
  }/api/v1/lends/change-to-app-if-camera-fails`;
  return FetchServicesCustomErrors({ endpoint, method: 'POST' });
}

export function createLoan(data) {
  const endpoint = `${import.meta.env.REACT_APP_API_SERVER}/pos/loan/create`;
  return FetchServicesCustomErrors({ endpoint, method: 'POST', body: data });
}

export function goToRegisterFullPmt() {
  const endpoint = `${import.meta.env.REACT_APP_API_SERVER}/api/v1/full-pmt-flow/go-to-register-full-payment`;
  return FetchServicesCustomErrors({ endpoint, method: 'POST' });
}

export function continueAfterFullPmtRegistered() {
  const endpoint = `${import.meta.env.REACT_APP_API_SERVER}/api/v1/full-pmt-flow/continue-after-full-payment-registered`;
  return FetchServicesCustomErrors({ endpoint, method: 'POST' });
}

export function goToCorrectFullPayment() {
  const endpoint = `${import.meta.env.REACT_APP_API_SERVER}/api/v1/full-pmt-flow/go-to-correct-full-payment`;
  return FetchServicesCustomErrors({ endpoint, method: 'POST' });
}

export function cancelConflictLendAfterFullPayment() {
  const endpoint = `${import.meta.env.REACT_APP_API_SERVER}/api/v1/full-pmt-flow/cancel-conflict-lend-after-full-payment`;
  return FetchServicesCustomErrors({ endpoint, method: 'POST' });
}

export function fetchDisbursementBankList() {
  // @returns {Promise<Array<{ bank_name: string, is_faster: boolean }>>}
  const endpoint = `${import.meta.env.REACT_APP_API_SERVER}/api/funnel/validation/disbursement-bank-list`;
  return FetchServicesCustomErrors({ endpoint });
}

export function fetchPrevDepositedBankList() {
  // @returns {Promise<Array<{ bank_name: string, bank_account_no: string, lend_id_of_selected_account: ObjectId }>>}
  const endpoint = `${import.meta.env.REACT_APP_API_SERVER}/api/funnel/validation/prev-deposited-bank-list`;
  return FetchServicesCustomErrors({ endpoint });
}

export function postBankAccountData(body) {
  const endpoint = `${import.meta.env.REACT_APP_API_SERVER}/api/funnel/validation/validate-bank-account`;
  return FetchServicesCustomErrors({ endpoint, method: 'POST', body });
}

export function cancelLendExternalProblems() {
  const endpoint = `${import.meta.env.REACT_APP_API_SERVER}/api/v1/complete-cancellation`;
  return FetchServicesCustomErrors({ endpoint, method: 'POST', body: {} });
}
