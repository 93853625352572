import React from 'react';

import type { Lend } from '@/globalTypes/lend.ts';

interface LendGlobalContextAttrs {
  lend: Lend;
  setLend: (lend: Lend) => void;
}

const LendGlobalContextDefaultValues: LendGlobalContextAttrs = {
  lend: null,
  setLend: () => {},
};

export const LendGlobalContext = React.createContext(LendGlobalContextDefaultValues);

export function LendGlobalContextProvider({ children }: { children: React.ReactNode }) {
  const [lend, setLend] = React.useState<Lend>(null);
  const memoizedContextValue = React.useMemo(
    () => ({
      lend,
      setLend,
    }),
    [lend]
  );

  return <LendGlobalContext.Provider value={memoizedContextValue}>{children}</LendGlobalContext.Provider>;
}
