import React from 'react';

import { Box } from '@mui/material';

import { CognitoContextProvider } from '../../context/CognitoContextProvider.tsx';
import CognitoCard from '@/components/Cognito/CognitoCard.ts';
import SignInContainer from '@/components/Cognito/CognitoContainer.ts';

export default function CognitoLayout({ children }: { children: React.ReactNode }) {
  return (
    <CognitoContextProvider>
      <SignInContainer>
        <CognitoCard>
          <Box display="flex" justifyContent="center">
            <Box
              sx={{ width: '50%', aspectRatio: 'auto' }}
              component="img"
              src={`${import.meta.env.BASE_URL}/img/logo_apurata.png`}
              alt="apurata"
            />
            <Box
              sx={{ width: '50%', aspectRatio: 'auto' }}
              component="img"
              src="/app/img/logo-acuotaz.png"
              alt="aCuotaz"
            />
          </Box>
          {children}
        </CognitoCard>
      </SignInContainer>
    </CognitoContextProvider>
  );
}
