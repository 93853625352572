import Cookies from 'js-cookie';
import FetchServicesCustomErrors from './BaseFetch';

export function FetchEndpointToGetSessionId() {
  const endpoint = `${import.meta.env.REACT_APP_API_SERVER}/create-session`;
  return FetchServicesCustomErrors({ endpoint, method: 'POST' });
}

export async function SaveUtmsInSession(utms) {
  const endpoint = `${import.meta.env.REACT_APP_API_SERVER}/api/v1/session/save-utms`;
  return FetchServicesCustomErrors({
    endpoint,
    method: 'POST',
    body: { utms },
  });
}

export function SaveSessionIdLocalStorage() {
  return navigator.locks.request('setSessionLocalStorageLock', async () => {
    const sessionId = Cookies.get('session_id_front_only');
    if (sessionId) {
      return sessionId;
    }
    try {
      const response = await FetchEndpointToGetSessionId();
      console.log('Hit to fetch session:', response.session_id);
      return response.session_id;
    } catch (err) {
      const errMsg = 'Error get SessionId';
      console.error(errMsg, err);
      throw new Error(errMsg, { cause: err });
    }
  });
}
