import React from 'react';
import iconBoundaryError from '../assets/error/iconBoundaryError.svg';

import { GetSessionId } from '../services/AuthService';

export default function ErrorFallback({ error, resetError }) {
  // don't use componentStack in production because it don't say anything
  return (
    <div className="container text-center" style={{ marginTop: '80px' }}>
      <h1>Oops! No cargó correctamente</h1>
      <button
        className="btn btn-info btn-lg"
        onClick={resetError}
        type="button"
      >
        Volver a cargar
      </button>
      <h4 className="mt-4">
        Error:
        {error.toString()}
      </h4>
      <div className="row text-left mt-30">
        <div className="col">
          { error.name === 'HttpError' ? (
            <p style={{ wordBreak: 'break-all' }}>
              <strong>Url: </strong>
              {error.request.url}
            </p>
          ) : (<div />)}
          <p>
            <strong>SessionId: </strong>
            {GetSessionId()}
          </p>
          <p>
            <strong>Navegador: </strong>
            {navigator.userAgent}
          </p>
        </div>
      </div>
      <img
        src={iconBoundaryError}
        alt="Error svg"
        style={{ maxHeight: '500px', width: '100%' }}
      />
    </div>
  );
}
