import Cookies from 'js-cookie';

export const IsLoggedIn = async () => {
  try {
    const response = await fetch(`${import.meta.env.REACT_APP_API_SERVER}/api/v1/get-my-user-id`, {
      method: 'GET',
    });
    const data = await response.json();
    return !!data.user_id;
  } catch (error) {
    console.error('Error checking login status:', error);
    return false;
  }
};

export function GetSessionId() {
  return Cookies.get('session_id_front_only');
}
