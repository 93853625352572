import * as Sentry from '@sentry/react';
import { ConnectionError, HttpError } from './CustomErrors';
import { sendErrWithExtra } from './SentryService';

function FetchServicesCustomErrors({
  endpoint, method = 'GET', body = null, extraHeaders = {},
}) {
  if (body && typeof body !== 'string') {
    // eslint-disable-next-line no-param-reassign
    body = JSON.stringify(body);
  }
  const init = {
    method,
    extraHeaders,
    body,
  };

  const request = new Request(endpoint, init);

  const throwOnResponseNotOk = async (response) => {
    if (!response.ok) {
      const responseClone = response.clone(); // Requests can only be read once
      const responseBody = await responseClone.text();
      throw new HttpError(response.status, response, request, responseBody);
    }
    return response;
  };

  const handleEmptyResponse = (response) => {
    const responseText = response.clone().text();
    responseText.then((data) => {
      if (data.length === 0) {
        const sentryMsg = `Empty response on ${method} '${endpoint}' | request.body='${body}'`;
        console.info('setSentryMsg: ', sentryMsg);
        Sentry.captureException(sentryMsg);
      }
    });
    return response;
  };

  const transformCustomError = (err) => {
    const connectionErrors = [
      'TypeError: Failed to fetch',
      'TypeError: Load failed',
      'TypeError: NetworkError when attempting to fetch resource',
    ];
    if (connectionErrors.includes(err.toString())) {
      throw new ConnectionError(endpoint, { cause: err });
    }
    if (err.name === 'HttpError') {
      throw err;
    }
    // eslint-disable-next-line no-param-reassign
    err.requestJson = {
      url: request.url,
      method: request.method,
    };
    if (!err.stack) {
      // eslint-disable-next-line no-param-reassign
      err.stack = new Error().stack;
    }
    throw err;
  };

  const tryParseJson = async (response) => {
    try {
      const contentType = response.headers.get('Content-Type');
      if (contentType && contentType.includes('application/json')) {
        return await response.json();
      }
      return response;
    } catch (error) {
      sendErrWithExtra(error, { response });
      return response;
    }
  };

  return fetch(request)
    .then(throwOnResponseNotOk) // side effect
    .then(handleEmptyResponse) // side effect
    .then(tryParseJson)
    .catch(transformCustomError);
}

export default FetchServicesCustomErrors;
