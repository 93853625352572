import React from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import GlobalContext from '../GlobalContext';
import { LendGlobalContextProvider } from './LendGlobalContext.tsx';

type Err = Error | string | null;

const theme = createTheme();

interface GlobalContextAttrs {
  children: React.ReactNode;
  error: Err;
  setError: (error: Err) => void;
}

export default function GlobalContextProviders({ children, error, setError }: GlobalContextAttrs) {
  const memoizedContextValue = React.useMemo(() => ({ error, setError }), [error]);

  return (
    <GlobalContext.Provider value={memoizedContextValue}>
      <LendGlobalContextProvider>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </LendGlobalContextProvider>
    </GlobalContext.Provider>
  );
}
