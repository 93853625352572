import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export default function LoadingSpinner({ show, text }) {
  if (show) {
    return (
      <div style={{ zIndex: 3 }} className="container-overlay">
        <span className="title">{text}</span>
        <Spinner
          className="spinner"
          animation="border"
          role="status"
          variant="light"
          size="sm"
        />
      </div>
    );
  }
  return null;
}
