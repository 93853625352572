import React from 'react';

/**
 * Need to go before lazy() calls
 */
function RenderLoader() {
  return <p>Cargando...</p>;
}

export default RenderLoader;
